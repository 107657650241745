import { getCountries } from "~/layers/master/services/useCountry"

export const useCountry = defineStore("country", {
  state: () => ({
    countries: [],
    indonesia: "",
  }),

  actions: {
    get() {
      getCountries({
        sortField: "name",
        sortOrder: "asc",
      }).then((r) => {
        if (r?.success) {
          this.countries = r?.data
          this.indonesia = r?.data?.find(
            (c: any): boolean => c?.code === "ID",
          )?.id
        }
      })
    },
  },
})
